import {
  MouseEvent,
  TouchEvent,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import MainService from '../../service/main/Main';

const PopUpBanner = ({}) => {
  const [open, setOpen] = useState(false);

  useLayoutEffect(() => {
    // 초기 렌더링 시에만 실행되도록 함
    setOpen(false);
    const container = document.getElementById('popup-display-none');
    if (container) {
      container.style.display = 'none';
    }
  }, []);

  const [popupList, setpopupList]: any = useState([]);
  const [popupIdx, setpopupIdx] = useState(0);
  const popup = async () => {
    try {
      const res = await MainService.popup();
      setpopupList(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useLayoutEffect(() => {
    if (
      popupList.length > 0 &&
      `${new Date().getFullYear()}${
        new Date().getMonth() + 1
      }${new Date().getDate()}` !== localStorage.popup
    ) {
      setOpen(true);
    }
  }, [popupList]);

  const [dragging, setDragging] = useState(false);
  const [clickPoint, setClickPoint] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleMouseDownEvent = (e: MouseEvent<HTMLDivElement>) => {
    setDragging(true);
    e.preventDefault();
    e.stopPropagation();

    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'auto';
      setClickPoint(e.pageX); // clickPoint는 처음 클릭한 지점
      setScrollLeft(containerRef.current.scrollLeft); // 스크롤움직인 거리, 처음은 0
    }
  };

  const handleMouseMoveEvent = (e: MouseEvent<HTMLDivElement>) => {
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음
    e.preventDefault();
    e.stopPropagation();
    if (containerRef.current) {
      const walk = e.pageX - clickPoint;
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };
  const handleTouchDownEvent = (e: TouchEvent<HTMLDivElement>) => {
    setDragging(true);
    e.stopPropagation();

    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'auto';
      setClickPoint(e.touches[0].pageX); // clickPoint는 처음 클릭한 지점
      setScrollLeft(containerRef.current.scrollLeft); // 스크롤움직인 거리, 처음은 0
    }
  };

  const handleTouchMoveEvent = (e: TouchEvent<HTMLDivElement>) => {
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음
    e.stopPropagation();
    if (containerRef.current) {
      const walk = e.touches[0].pageX - clickPoint;
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  const handleMouseEventEnd = (e: MouseEvent<HTMLDivElement> | TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'smooth';
      containerRef.current.scrollLeft =
        containerRef.current.scrollLeft -
        (containerRef.current.scrollLeft % 312) +
        (containerRef.current.scrollLeft - scrollLeft > 0 ? +312 : 0);

      if (containerRef.current.scrollLeft % 312 === 0) {
        setpopupIdx(containerRef.current.scrollLeft / 312);
        console.log(containerRef.current.scrollLeft / 312);
      } else {
        setpopupIdx(
          (containerRef.current.scrollLeft -
            (containerRef.current.scrollLeft % 312) +
            (containerRef.current.scrollLeft - scrollLeft > 0 ? +312 : 0)) /
            312
        );
        console.log(
          containerRef.current.scrollLeft -
            (containerRef.current.scrollLeft % 312) +
            (containerRef.current.scrollLeft - scrollLeft > 0 ? +312 : 0)
        );
      }
    }
  };

  const handleNavigatePage = (
    e: MouseEvent<HTMLDivElement> | TouchEvent,
    idx: number
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'smooth';
      containerRef.current.scrollLeft = idx * 312;
    }
    setpopupIdx(idx);
  };

  const [tran, setTran] = useState(0);
  useLayoutEffect(() => {
    if (open) {
      setTimeout(() => {
        setTran(1);
      }, 100);
    }
  }, [open]);
  useLayoutEffect(() => {
    popup();
  }, []);

  if (window.navigator.userAgent === 'ReactSnap') {
    return <></>;
  }
  return (
    <div
      id='popup-display-none'
      style={{
        position: 'fixed',
        display: open ? 'flex' : 'none',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 20,
        background: '#00000070',
        padding: '24px',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        overflowY: 'auto',
      }}
      onClick={(e: any) => {
        if (!dragging) {
          setOpen(false);
        } else {
          setDragging(false);
        }
      }}
    >
      <div
        style={{
          opacity: tran ? '100%' : '0%',
          flexDirection: 'column',
          width: '312px',
          transition: 'all 1.5s ease',
        }}
      >
        <div
          style={{
            width: '312px',
            height: '450px',
            display: 'flex',
            overflow: 'hidden',
            transition: '1s',
            left: 0,
            zIndex: 1,
            cursor: 'default',
            flexWrap: 'nowrap',
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
          }}
          ref={containerRef}
          onMouseDown={handleMouseDownEvent}
          onTouchStart={handleTouchDownEvent}
          onMouseLeave={handleMouseEventEnd}
          onTouchEnd={handleMouseEventEnd}
          onMouseUp={(e: any) => {
            handleMouseEventEnd(e);
            setDragging(false);
          }}
          onTouchMove={handleTouchMoveEvent}
          onMouseMove={handleMouseMoveEvent}
          onClick={(e: any) => e.stopPropagation()}
        >
          {popupList.map((el: any) => (
            <div
              key={el.pIdx}
              style={{
                display: 'flex',
                minWidth: '312px',
                maxWidth: '312px',
                height: '450px',
                overflowY: 'auto',
                flexWrap: 'nowrap',
                overflowX: 'hidden',
              }}
            >
              <img
                src={`${process.env.REACT_APP_HOST}img/${el.img}`}
                style={{ width: '312px' }}
                alt=''
              />
            </div>
          ))}
        </div>
        <div
          className='flex-row center-x bg-FFFFFF padding-b-16'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{ cursor: 'default' }}
        >
          {popupList.map((el: any, idx: number) => (
            <div
              key={el.pIdx}
              style={{ padding: '4px', cursor: 'pointer' }}
              onClick={(event) => handleNavigatePage(event, idx)}
            >
              <div
                className='transition'
                style={{
                  width: popupIdx === idx ? '24px' : '8px',
                  height: '8px',
                  background: popupIdx === idx ? '#FFFFFF' : '#FFFFFF50',
                  borderRadius: '999px',
                  border: '1px solid #171e2656',
                  boxShadow: '1px 1px 1px 0px #00000040',
                }}
              />
            </div>
          ))}
        </div>
        <div
          className='font-medium f-size-14 line-h-20 color-text-tertiary padding-8-16'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: '#F0F0F0',
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px',
          }}
        >
          <div
            onClick={() => {
              localStorage.setItem(
                'popup',
                `${new Date().getFullYear()}${
                  new Date().getMonth() + 1
                }${new Date().getDate()}`
              );
              setOpen(false);
            }}
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <img
              src='/asset/images/check/Check24Gray2.svg'
              style={{ color: '#171E268F' }}
              alt=''
            />
            <div>오늘 하루 보지 않기</div>
          </div>
          <div
            onClick={() => setOpen(false)}
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <img src='/asset/images/close/CloseGray24.svg' alt='' />
            <div>닫기</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpBanner;
