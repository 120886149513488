import './Inquiry.css';
import Select from 'react-select';
import { TopNav2 } from '../../component/mobile/Topnav';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../component/mobile/Button';
import useDidMountEffect from '../../hooks/UseDidMountEffect';
import { isMobile } from '../../component/function/function';
import close from './../../asset/images/close/close_black.svg';
import { OnToggle } from '../../store/inquirySlice';
import BoardService from '../../service/board/Board';
import UserService from '../../service/checkAccount/CheckAccount';

interface PropsType {
  address: string;
}
const InquiryWrite = ({ address, setOnInquiry, footer }: any) => {
  const [btnOn, setBtnOn] = useState(false);
  const [type, setType] = useState('');
  const [content, setContent] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [purpose, setPurpose] = useState('');
  const [autoFillEmail, setAutoFillEmail] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [currentAddress, setCurrentAddress] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  // const state = useSelector((state: any) => {
  //   return state.user;
  // });
  // const state2 = useSelector((state2: any) => {
  //   return state2.inquiry;
  // });

  useEffect(() => {
    if (location.state && location.state.address) {
      setCurrentAddress(location.state.address);
    } else {
      setCurrentAddress(address);
    }
  }, [location.state, address]);

  const dummy = [
    { value: 'M', label: '매매 신청' },
    { value: 'A', label: '가격 분석 문의' },
    { value: 'S', label: '농지 찾기 문의' },
    { value: 'F', label: '내 농장 진단 문의' },
    { value: 'H', label: '내 집 짓기 문의' },
    { value: 'P', label: '농지 연금 문의' },
    { value: 'B', label: '충전 및 포인트 이용 문의' },
    { value: 'R', label: '시스템 오류 제보' },
    {
      value: 'C',
      label: '트랜스파머 서비스 불편사항',
    },
    { value: 'E', label: '기타' },
  ];

  const checkBtn = () => {
    if (!footer && currentAddress && purpose === '') {
      alert('주소 분석 목적을 선택해주세요');
    } else if (type === '') {
      alert('유형을 선택해주세요');
    } else if (content === '') {
      alert('문의사항을 입력해주세요');
    } else if (email === '') {
      alert('이메일을 입력해주세요');
    } else if (phone === '') {
      alert('연락처를 입력해주세요');
    } else {
      Submit();
    }
  };
  const checkBtnOn = () => {
    if (type !== '' && content !== '' && email !== '' && phone !== '') {
      setBtnOn(true);
    } else {
      setBtnOn(false);
    }
  };

  const handlePurposeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedPurpose = e.target.value;
    setPurpose(selectedPurpose);
  };

  const CheckEmail = async () => {
    setAutoFillEmail(false);
    try {
      const result = await UserService.checkInfo();
      setAutoFillEmail(true);
      setEmail(result.email);
      setPhone(result.phone);
    } catch (error) {
      setAutoFillEmail(false);
      alert('해당 기능은 로그인 후 사용 가능합니다.');
    }
  };

  const handleAutoFillEmail = () => {
    setAutoFillEmail((prevState) => !prevState);
    if (!autoFillEmail) {
      CheckEmail();
    } else {
      setEmail('');
      setPhone('');
    }
  };

  const Submit = async () => {
    setLoading(true);
    try {
      let formData = new FormData();
      // formData.append("fileImg", []);
      formData.append('type', type);
      formData.append('title', footer ? '' : currentAddress);
      formData.append('purpose', footer ? '' : purpose);
      formData.append('content', content);
      formData.append('email', email);
      formData.append('phone', phone);
      await BoardService.WriteInuqiry(formData);
      setLoading(false);
      setBtnOn(false);
      setSuccessMessage(true);
      setTimeout(() => {
        setSuccessMessage(false);
        if (isMobile(window.innerWidth)) {
          navigate(-1);
        } else {
          setOnInquiry(false);
        }
      }, 2000);
    } catch (error) {
      setLoading(false);
      console.error('Submit 함수에서 에러 발생:', error);
    }
  };

  useEffect(() => {
    checkBtnOn();
  }, [purpose, type, content, email, phone]);

  const customStyles = {
    placeholder: (provided: any) => ({
      ...provided,
      color: 'rgba(23, 30, 38, 0.4)',
      fontSize: '14px',
    }),
  };

  return (
    <div
      className={`flex-column w-100per browser-fixed browser-w-100per browser-h-100per  browser-center-x browser-center-y relative${
        footer && `fixed`
      }`}
      style={
        isMobile(window.innerWidth)
          ? {}
          : {
              zIndex: 999,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: '#00000075',
            }
      }
    >
      <TopNav2 text='문의하기' hamburger back />
      <div
        className='inquiry-container'
        style={{
          height: window.innerHeight >= 748 ? 'fit-content' : '100%',
          paddingTop: window.innerHeight >= 748 ? '0' : '30px',
        }}
      >
        <div
          className='flex-column gap-24 browser-w-360 browser-bg-FFFFFF browser-border-radius-16'
          style={{
            height: window.innerHeight >= 748 ? '748px' : '100%',
            overflowY: 'auto',
            padding: isMobile(window.innerWidth)
              ? '24px 16px 40px'
              : '9px 16px 96px 16px',
          }}
        >
          <div
            className='browser flex-row space-between'
            style={{
              borderBottom: '0.8px solid #efefef',
              alignItems: 'center',
            }}
          >
            <div
              className='font-bold f-size-16 line-h-24 color-text-primary'
              style={{ flex: 1, textAlign: 'center', paddingBottom: '9px' }}
            >
              문의하기
            </div>
            <img
              src={close}
              alt=''
              className='hover'
              onClick={() => setOnInquiry(false)}
            />
          </div>
          {!footer && (
            <div className='font-medium f-size-18 line-h-30 color-text-primary'>
              {footer ? '' : currentAddress}
            </div>
          )}

          {!footer && currentAddress && (
            <div className='inquiry-content-box'>
              <div
                className='inquiry-content-box-title'
                style={{ color: 'red' }}
              >
                매매 신청
              </div>
              <div className='select-purpose-items'>
                <label className='select-purpose-item'>
                  <input
                    type='radio'
                    name='purpose'
                    value='B'
                    checked={purpose === 'B'}
                    onChange={handlePurposeChange}
                  />
                  <img
                    src={
                      purpose === 'B'
                        ? '/asset/images/check/radio-btn-on.svg'
                        : '/asset/images/check/radio-btn-off.svg'
                    }
                    alt=''
                  />
                  <span>사고 싶어요</span>
                </label>
                <label className='select-purpose-item'>
                  <input
                    type='radio'
                    name='purpose'
                    value='S'
                    checked={purpose === 'S'}
                    onChange={handlePurposeChange}
                  />
                  <img
                    src={
                      purpose === 'S'
                        ? '/asset/images/check/radio-btn-on.svg'
                        : '/asset/images/check/radio-btn-off.svg'
                    }
                    alt=''
                  />
                  <span>팔고 싶어요</span>
                </label>
                <label className='select-purpose-item'>
                  <input
                    type='radio'
                    name='purpose'
                    value='N'
                    checked={purpose === 'N'}
                    onChange={handlePurposeChange}
                  />
                  <img
                    src={
                      purpose === 'N'
                        ? '/asset/images/check/radio-btn-on.svg'
                        : '/asset/images/check/radio-btn-off.svg'
                    }
                    alt=''
                  />
                  <span>해당 없음</span>
                </label>
              </div>
            </div>
          )}

          <div className='inquiry-content-box'>
            <div className='inquiry-content-box-title'>문의 유형</div>
            <Select
              className='inquiry-content-box-select font-medium f-size-14 line-h-24 color-text-primary border-radius-5 w-100per pointer'
              options={dummy}
              styles={customStyles}
              placeholder='유형을 선택해주세요'
              onChange={(e: any) => {
                setType(e.value);
              }}
            />
          </div>

          <div className='inquiry-content-box'>
            <div className='inquiry-content-box-title'>
              <span>문의 내용</span>
              <span>
                {content.length}
                {` `}
                <span className='limit-2000-text'>/ 2000 </span>
              </span>
            </div>
            <div>
              <div className='inquiry-content-descript'>
                매매 신청 시 아래 내용을 보내주세요(접수 후 해당 지역 파트너
                중개사님께 연결해 드립니다)
              </div>
              <div
                className='inquiry-content-descript'
                style={{ color: 'black' }}
              >
                1. 주소{' '}
                <span>
                  (문의화면 위에 주소가 자동으로 보여지는 <br /> 경우, 매매
                  주소지와 다른 경우에만 작성해 주세요)
                </span>
              </div>
              <div
                className='inquiry-content-descript'
                style={{ color: 'black' }}
              >
                2. 희망 매도가(또는 매수가){' '}
                <ul className='custom-list'>
                  <li>
                    가격 단위를 정확히 확인해 작성해 주세요.
                    <div style={{ marginLeft: '12px' }}>
                      4천5백만원(O), 45,000,000원(O) 450만원(X)
                    </div>
                  </li>
                  <li>가격을 모르시는 경우에는 가격분석을 요청해 주세요.</li>
                </ul>
              </div>
              <div
                className='inquiry-content-descript'
                style={{ color: 'black' }}
              >
                3. 간략한 설명(매물 현황 또는 매수 조건){' '}
              </div>
            </div>

            <textarea
              className='h-100per w-100per border-171E26 border-radius-8 min-h-160 font-medium f-size-14 line-h-24 color-text-primary pointer'
              maxLength={2000}
              placeholder='문의 내용을 입력해주세요.'
              onChange={(e: any) => {
                setContent(e.target.value);
              }}
            />
          </div>
          <div className='inquiry-content-box'>
            <div className='inquiry-content-box-title'>
              <span>이메일</span>
              <span className='auto-fill-email' onClick={handleAutoFillEmail}>
                <img
                  src={
                    autoFillEmail
                      ? '/asset/images/check/Check24GreenCircle2.svg'
                      : '/asset/images/check/checkWithGrayCircle.svg'
                  }
                  alt=''
                />
                <span>가입 정보로 받기</span>
              </span>
            </div>
            <input
              value={email}
              className='w-100per padding-12-8 border-171E26 border-radius-5 font-medium f-size-14 line-h-24 color-text-primary h-48 pointer'
              placeholder='회신 받을 이메일을 입력해주세요.'
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className='inquiry-content-box'>
            <div className='inquiry-content-box-title'>
              <span>연락처</span>
            </div>
            <input
              type='tel'
              value={phone}
              maxLength={11}
              className='w-100per padding-12-8 border-171E26 border-radius-5 font-medium f-size-14 line-h-24 color-text-primary h-48 pointer'
              placeholder='‘-’를 제외한 휴대폰 번호를 작성해주세요.'
              onChange={(e: any) => {
                setPhone(e.target.value);
              }}
            />
          </div>
          <div className='w-100per bg-light-gray-100 border-radius-8 padding-12-16 space-between flex-row center-y'>
            <div className='m-b2-14-m color-dark-primary'>고객센터</div>
            <div className='flex-row gap-4 center-y'>
              <img src='/asset/images/icon/phone.svg' />
              <div className='m-b2-14-m color-text-tertiary'>
                02)555-2822 (평일 09:00~18:00)
              </div>
            </div>
          </div>
          <a
            className='link-to-consulting-box'
            href='https://transfarmer.co.kr/promotion/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8-%EB%A7%9E%EC%B6%A4-%ED%86%A0%EC%A7%80-%EC%BB%A8%EC%84%A4%ED%8C%85'
          >
            <div className='link-to-consulting-text'>
              <span>토지에 대한 가장 정확하고 정직한 분석</span>
              <span className='link-text-bold'>
                <span>토지 맞춤 컨설팅</span>
                <img src='/asset/images/arrow/Right24.svg' alt='' />
              </span>
            </div>
            <img src='/asset/images/promotion/consulting.svg' alt='' />
          </a>
          {isMobile(window.innerWidth) ? (
            <Button
              loading={loading}
              description='접수'
              on={btnOn}
              onClick={() => {
                checkBtn();
              }}
            />
          ) : (
            <div className='fixed-btn'>
              <Button
                loading={loading}
                description='접수'
                on={btnOn}
                onClick={() => {
                  checkBtn();
                }}
              />
            </div>
          )}
        </div>
        {successMessage && (
          <div
            className='fixed z-100 bg-blue-gray-800 border-radius-8 padding-8 w-328 gap-10 flex-row '
            style={{
              bottom: '16%',
              left: '50%',
              transform: 'translateX(-50%)',
              opacity: 0.9,
              zIndex: 3000,
            }}
          >
            <div className='font-bold f-size-14 line-h-20 color-white'>✓</div>
            <div className='font-bold f-size-14 line-h-20 color-white'>
              문의가 접수되었습니다!
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InquiryWrite;
